<template>
    <div class="my-0 py-0" style="background: var(--v-component-base) !important">
        <v-card flat>
            <v-card-text>
                <v-row align="center" justify="end">
                    <v-spacer></v-spacer>
                    <v-chip @click="getSailedBookings" :outlined="!filterSearch" class="mx-1" color="danger"
                        :close="filterSearch" @click:close="getBookings">
                        <v-icon left>warning</v-icon>
                        Sailed Bookings
                    </v-chip>
                    <v-col cols="12" sm="6" md="4">
                        <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="params.search" clearable
                            placeholder="Search">
                        </el-input>
                    </v-col>
                </v-row>
                <v-data-table class="mt-3" dense disable-pagination fixed-header hide-default-footer :loading="loading"
                    :items="bookings.data" :headers="headers" height="62vh" :calculate-widths="true"
                    style="cursor: pointer">
                    <template v-slot:[`item.etd`]="{ item }">
                        <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-chip close v-if="item.etd" small outlined style="border: none" @click:close="item.etd = null, saveBooking(item)">
                                        {{ formatDate(item.etd) }}
                                    </v-chip>
                                    <v-chip v-else small outlined style="border: none">
                                        <v-icon color="primary"> add </v-icon>
                                    </v-chip>
                                </div>
                            </template>
                            <v-date-picker v-model="item.etd" no-title scrollable @input="saveBooking(item)">
                            </v-date-picker>
                        </v-menu>
                    </template>
                    <template v-slot:[`item.atd`]="{ item }">
                        <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-chip close v-if="item.atd" small outlined style="border: none" @click:close="item.atd = null, saveBooking(item)">
                                        {{ formatDate(item.atd) }}
                                    </v-chip>
                                    <v-chip v-else small outlined style="border: none">
                                        <v-icon color="primary"> add </v-icon>
                                    </v-chip>
                                </div>
                            </template>
                            <v-date-picker v-model="item.atd" no-title scrollable @input="saveBooking(item)">
                            </v-date-picker>
                        </v-menu>
                    </template>
                    <template v-slot:[`item.eta`]="{ item }">
                        <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-chip close v-if="item.eta" small outlined style="border: none" @click:close="item.eta = null, saveBooking(item)">
                                        {{ formatDate(item.eta) }}
                                    </v-chip>
                                    <v-chip v-else small outlined style="border: none">
                                        <v-icon color="primary"> add </v-icon>
                                    </v-chip>
                                </div>
                            </template>
                            <v-date-picker v-model="item.eta" no-title scrollable @input="saveBooking(item)">
                            </v-date-picker>
                        </v-menu>
                    </template>
                    <template v-slot:[`item.ata`]="{ item }">
                        <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-chip close v-if="item.ata" small outlined style="border: none" @click:close="item.ata = null, saveBooking(item)">
                                        {{ formatDate(item.ata) }}
                                    </v-chip>
                                    <v-chip v-else small outlined style="border: none">
                                        <v-icon color="primary"> add </v-icon>
                                    </v-chip>
                                </div>
                            </template>
                            <v-date-picker v-model="item.ata" no-title scrollable @input="saveBooking(item)">
                            </v-date-picker>
                        </v-menu>
                    </template>
                </v-data-table>
                <v-col cols="12" class="text-center">
                    <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :pager-count="5"
                        :page-size.sync="params.limit" :page-sizes="[18, 25, 50, 100]" :layout="!$vuetify.breakpoint.mobile
                            ? 'sizes, prev, pager, next, jumper, total'
                            : 'prev, pager, next'
                            " :total="bookings.total">
                    </el-pagination>
                </v-col>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import dateFormat from "dateformat";
export default {
    components: {

    },
    data: () => ({
        page: 1,
        loading: false,
        filterSearch: false,
        searchTimer: null,
        params: {
            limit: 18,
            offset: 0,
            search: null,
            filter: { isActive: [true] },
        },
        bookings: {
            total: 0,
            data: []
        },
        headers: [
            {
                text: 'Order Number',
                value: 'orderNo',
                align: 'center',
            },
            {
                text: 'Vessel',
                value: 'mainCarriageVesselName',
                align: 'center',
            },
            {
                text: 'Voyage',
                value: 'mainCarriageConveyanceNumber',
                align: 'center'
            },
            {
                text: 'Port Of Load',
                value: 'portOfLoadCity',
                align: 'center'
            },
            {
                text: 'Port of Discharge',
                value: 'portOfDischargeValue',
                align: 'center'
            },
            {
                text: 'Final Destination',
                value: 'finalDestinationValue',
                align: 'center'
            },
            {
                text: 'ETD',
                value: 'etd',
                align: 'center'
            },
            {
                text: 'ATD',
                value: 'atd',
                align: 'center'
            },
            {
                text: 'ETA',
                value: 'eta',
                align: 'center'
            },
            {
                text: 'ATA',
                value: 'ata',
                align: 'center'
            },
            {
                text: 'Shipping Line',
                value: 'carrierName',
                align: 'center',
            },
            {
                text: 'Shipping Line Reference',
                value: 'carrierReferenceNumber',
                align: 'center',
            },

        ]
    }),
    mounted() {
        this.getBookings()
    },
    created() {

    },
    computed: {

    },
    watch: {
        'params.search': {
            async handler() {
                this.page = 1;
                await this.getBookings()
            }
        },
        'params.limit': {
            async handler() {
                this.page = 1;
                await this.getBookings()
            }
        },
        'params.offset': {
            async handler() {
                await this.getBookings()
            }
        },
        'page': {
            async handler(val) { this.params.offset = (val-1) * this.params.limit }
        },
    },
    methods: {
        async getBookings() {
            this.filterSearch = false
            if (this.searchTimer) { clearTimeout(this.searchTimer) }
            this.loading = true
            this.searchTimer = setTimeout(async () => {
                this.bookings = await this.$API.getBookingsAdmin({ params: this.params });

                this.loading = false
            }, 500)
        },
        async getSailedBookings() {
            this.filterSearch = true
            if (this.searchTimer) { clearTimeout(this.searchTimer) }
            this.loading = true
            this.searchTimer = setTimeout(async () => {
                this.bookings = await this.$API.getSailedBookingsAdmin();
                this.loading = false
            }, 500)
        },
        async saveBooking(booking) {
            console.log(booking);
            this.loading = true
            try {
                await this.$API.updateBookingAdmin(booking)
                this.$message({
                    type: 'success',
                    message: `Booking ${booking.orderNo} Updated!`
                })
            } catch (e) {
                this.$message({
                    type: 'error',
                    message: `There was an error trying to update Booking ${booking.orderNo}.`
                })
            }
            this.loading = false
        },
        formatDate(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy");
            }
            return result;
        },
        formatDateTime(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
            }
            return result;
        },
        setTextColor(date) {
            if (date) {
                return 'blue'
            } else {
                return 'orange'
            }
        }
    },
};
</script>
  
<style scoped>
.scroll {
    overflow-y: scroll;
}
</style>